.dashboard-tickets{
  width: 90%;
  margin: auto;
}
.tickets-container{
  width: 100%;
  height: fit-content;
  min-height: 50px;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 40px;
}
.ticket-header{
  height: 35px;
  width: 100%;
  display: flex;
  align-items: center;
  p{
    margin: 0;
    padding-left: 20px;
    font-size: 18px;
    font-weight: bold;
    color: white;
  }
}
.color-red{
  border: 1px solid #fd5656;
  box-shadow: 0 0 25px 2px #fd5656;
}
.header-red{
  background: #fd5656;
}
.color-blue{
  border: 1px solid #7171ff;
  box-shadow: 0 0 25px 2px #7171ff;
}
.header-blue{
  background: #7171ff;
}
.color-yellow{
  border: 1px solid #ffff5b;
  box-shadow: 0 0 25px 2px #ffff5b;
}
.header-yellow{
  background: #ffff5b;
}
.color-gray{
  border: 1px solid #b6b6b6;
  box-shadow: 0 0 25px 2px #b6b6b6;
}
.header-gray{
  background: #b6b6b6;
}
.view-all-admins{
  padding: 8px 30px;
  height: 25px;
  background: #b6b6b6;
  border: #b6b6b6;
  border-radius: 4px;
  text-decoration: none;
  color: white;
}
.view-all-logs{
  padding: 8px 30px;
  height: 25px;
  background: #fd5656;
  border: #fd5656;
  border-radius: 4px;
  text-decoration: none;
  color: white;
}
.view-all-accounts{
  padding: 8px 30px;
  height: 25px;
  background: #7171ff;
  border: #7171ff;
  border-radius: 4px;
  text-decoration: none;
  color: white;
}
.view-all-users{
  padding: 8px 30px;
  height: 25px;
  background: #ffff5b;
  border: #ffff5b;
  border-radius: 4px;
  text-decoration: none;
  color: white;
}
.table-total-count{
  margin-top: 40px;
}