.header-container{
  width: 100%;
  height: 80px;
  background: #40ae49;
  display: flex;
  align-items: center;
  .header-content{
    margin: auto;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .navbar-list{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    li{
      margin: 0 10px;
    }
    a{
      color: white;
      text-decoration: none;
      font-size: 17px;
    }
  }
}
.header-logo{
  height: 30px;
}
@import url("https://fonts.googleapis.com/css2?family=Lato&family=Open+Sans:ital,wght@0,600;1,400&family=Roboto:ital,wght@1,300&display=swap");
.policy .HeaderTitle {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  margin-top: 35px;
}

.policy .content {
  width: 85%;
  margin: auto;
  margin-top: 80px;
}

.policy .sectionTitle {
  font-size: 20px;
}

.policy .text {
  font-family: 'Lato', sans-serif;
  margin-top: 30px;
  line-height: 30px;
}

.policy .margin {
  margin-top: 45px;
}

.policy ul, .policy li {
  margin-left: 15px;
  list-style-type: circle;
  line-height: 25px;
}

.policy .marginbtn {
  margin-bottom: 150px;
}
.blue-button{
  background: #0a56f7;
  color: white;
  border: none;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  padding: 8px 20px;
}
.aside-modal{
  top: 0;
  margin-top: 0px !important;
  right: 0px;
  position: absolute !important;
  height: 100vh;
  .modal-content{
    border-radius: 8px 0px 8px 0px !important;
    height: 100%;
  }
}
.table tbody tr:hover{
  cursor: pointer;
  td{
    background: #40ae49;
    color: white;
  }
}