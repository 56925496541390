.auth-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.auth-logo {
  width: 200px;
}

.auth-banner {
  width: 300px;
  margin-top: 30px;
}

.authorization-form {
  width: 300px;
}

.auth-title {
  margin: 15px 0px;
  text-align: left;
}

.button-width {
  width: 100%;
  background: #0584be !important;
  color: white !important;
}

.form-group {
  margin-bottom: 25px;
}
.form-group label {
  width: 100%;
  text-align: left;
}

.authorization-error {
  color: red;
  text-align: left;
  width: 100%;
  display: flex;
}

.auth-action-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reset-password {
  height: -moz-fit-content;
  height: fit-content;
  color: #4444ea;
  text-decoration: underline;
  cursor: pointer;
}/*# sourceMappingURL=Auth.css.map */