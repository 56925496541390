.form-checkboxes{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .form-check{
    margin-left: 15px;
    &:first-child{
      margin-left: 0;
    }
  }
}
.reset-user-password-container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.form-container{
  width: 90%;
  height: fit-content;
  max-width: 400px;
}